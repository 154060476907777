import React from 'react'
import { SEO, Layout } from '@global'

const CovidPage = () => {
  return (
    <div className="relative">
      <SEO title="Covid 19 Statement" />
      <Layout>
        <section className="py-10 md:py-16">
          <div className="container px-8 max-w-2xl">
            <h1 className="text-blue-400 text-3xl lg:text-4xl capitalize font-bold">
              COVID-19 Update
            </h1>
            <date className="text-blue-300 font-bold uppercase text-sm mt-1 block">
              March 23, 2020
            </date>
            <p className="text-sm sm:text-lg text-gray-400 mt-8 leading-relaxed">
              We offer our sincerest thanks for allowing us to be a part of your
              project. We hope that our skills and professionalism made the
              decision to utilize our services an easy one for you to make. You
              could have opted to use your resources elsewhere and we appreciate
              your business. In these times of uncertainty it is our goal to
              continue to be the company that made you feel comfortable and take
              our services even further in regards to COVID 19.
            </p>
            <p className="text-sm sm:text-lg text-gray-400 mt-5 leading-relaxed">
              The CDC and WHO have released data on protocols to reduce the risk
              of contamination. The Federal and State governments, as well as
              local municipalities have adopted these practices. OSHA has
              released a statement directly addressing the workplace.
              Tradespeople are classified in the low risk category as we are
              typically isolated from the public for other safety concerns. We
              are prepared to go beyond that and isolate ourselves from anyone
              else on the premises with the use of temporary plastic sheet walls
              and negative airflow if worksite allows for these measures.
              Additionally, we will wear surgical masks when available while
              walking outside of the protected area, all surfaces we come into
              contact with outside the protected area will be wiped down with
              sanitizing solution throughout the day and before we leave for the
              evening, and above all, we will be monitoring our team’s health
              throughout the process. They have been educated to closely keep
              their hands clean, to cover their mouth when coughing or sneezing
              with their elbow, to not share drinks/food/etc., to consider
              frequently touched items, to keep their hands away from their
              faces, and to daily check their temperatures before leaving their
              homes. We want to protect our team and the people and places they
              come in contact with.
            </p>
            <p className="text-sm sm:text-lg text-gray-400 mt-5 leading-relaxed">
              With some deciding to pull back from continuing with projects and
              asking to delay the start until things change with the virus, we
              want to remind you that our pricing and timeline can only be
              guaranteed now. We believe that we can operate a business that
              thrives during this time without taking unnecessary risks and turn
              out incredible work, which is vital to the local economy, society
              in general, and specifically the families supported by our
              company. If your project is delayed, we may not have the
              availability in the future to perform our services, nor can we
              honor the pricing as materials and demand will climb higher as the
              recovery is prolonged. We strongly urge you to work with us to
              ensure your project gets completed while the time and price is
              right to do so.
            </p>
            <p className="text-sm sm:text-lg text-gray-400 mt-5 leading-relaxed">
              Please let us know if we can further put your mind at ease or
              answer any questions you may have.
            </p>
            <p className="text-sm sm:text-lg text-blue-300 mt-12 mb-20 font-bold">
              — Dan Updike, Owner
            </p>
          </div>
        </section>
      </Layout>
    </div>
  )
}

export default CovidPage
